import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Query, QueryComponentOptions } from "@apollo/react-components";

import * as fragments from "./fragments";
import { useGlobalState } from "./hooks";

import {
  globalData as globalDataQuery,
  globalDataVariables as globalDataQueryVariables,
  system as systemQuery,
  systemVariables as systemQueryVariables,
  systems as SYSTEMS_QUERY
} from "./core-types";

// Initial query, fetch data crucial for rendering
export const globalData = gql`
  ${fragments.administrator}
  ${fragments.system}
  query globalData($administratorId: ID!) {
    administrator(id: $administratorId) {
      ...Administrator
      selectedSystem {
        ...System
      }
    }
    systems {
      id
      name
      type
    }
  }
`;

type GlobalDataQueryProps = Omit<
  QueryComponentOptions<globalDataQuery, globalDataQueryVariables>,
  "query"
>;

export function GlobalDataQuery(props: GlobalDataQueryProps) {
  const [state] = useGlobalState();
  const variables: globalDataQueryVariables = {
    administratorId: state.user.id
  };
  return (
    <Query<globalDataQuery, globalDataQueryVariables>
      query={globalData}
      variables={variables}
      displayName="GlobalDataQuery"
      {...props}
    />
  );
}

export function useGlobalData(): globalDataQuery {
  const [state] = useGlobalState();
  const variables: globalDataQueryVariables = {
    administratorId: state.user.id
  };
  const { data } = useQuery<globalDataQuery, globalDataQueryVariables>(
    globalData,
    {
      variables
    }
  );

  if (!data) {
    throw new Error("Global data not loaded");
  }

  return data;
}

export const dockGroup = gql`
  ${fragments.dockGroup}
  query dockGroup($systemId: ID!, $id: ID!) {
    dockGroup(systemId: $systemId, id: $id) {
      ...DockGroup
    }
  }
`;

export const dockGroupTimeline = gql`
  query dockGroupTimeline($systemId: ID!, $id: ID!) {
    dockGroup(systemId: $systemId, id: $id) {
      id
      mutationLog {
        createdAt
        mutations
      }
    }
  }
`;

export const dockGroupComments = gql`
  query dockGroupComments($systemId: ID!, $id: ID!) {
    dockGroup(systemId: $systemId, id: $id) {
      id
      adminComments {
        id
        administrator {
          id
          name
        }
        comment
        createdAt
      }
    }
  }
`;

export const dockGroupNameIsAvailable = gql`
  query dockGroupNameIsAvailable($systemId: ID!, $name: String) {
    dockGroupNameIsAvailable(systemId: $systemId, name: $name)
  }
`;

export const vehicleNameIsAvailable = gql`
  query vehicleNameIsAvailable($systemId: ID!, $name: String!) {
    vehicleNameIsAvailable(systemId: $systemId, name: $name) {
      id
    }
  }
`;

export const vehicleNumberIsAvailable = gql`
  query vehicleNumberIsAvailable($systemId: ID!, $number: String!) {
    vehicleNumberIsAvailable(systemId: $systemId, number: $number) {
      id
    }
  }
`;

export const discountCampaignCodeIsAvailable = gql`
  query discountCampaignCodeIsAvailable($systemId: ID!, $code: String!) {
    discountCampaignCodeIsAvailable(systemId: $systemId, code: $code)
  }
`;

export const discountCampaignNameIsAvailable = gql`
  query discountCampaignNameIsAvailable($systemId: ID!, $name: String!) {
    discountCampaignNameIsAvailable(systemId: $systemId, name: $name)
  }
`;

// Products
export const products = gql`
  query products(
    $page: Int
    $perPage: Int
    $orderBy: String
    $showDeleted: Boolean
    $systemId: ID!
  ) {
    products(
      systemId: $systemId
      options: {
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        showDeleted: $showDeleted
      }
    ) {
      id
      name
      enabled
      deletedAt
      purchaseChannels
      availableForValueCode
    }
  }
`;

export const productsPaginated = gql`
  query productsPaginated(
    $systemId: ID!
    $offset: Int
    $limit: Int
    $orderBy: String
    $orderDirection: OrderDirection
    $filters: [Filter]
    $complexFilters: [ComplexFilter]
  ) {
    productsPaginated(
      systemId: $systemId
      pagination: { limit: $limit, offset: $offset }
      options: {
        orderBy: $orderBy
        orderDirection: $orderDirection
        filters: $filters
        complexFilters: $complexFilters
      }
    ) {
      paginationInfo {
        hasNextPage
        hasPreviousPage
        totalRowCount
      }
      rows {
        id
        name
        enabled
        deletedAt
        purchaseChannels
        partners {
          id
          name
        }
      }
    }
  }
`;

// Administrators
export const administrators = gql`
  query administratorsConnection(
    $systemId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
    $orderBy: String
    $orderDirection: OrderDirection
    $filters: [Filter]
    $complexFilters: [ComplexFilter]
    $showInvited: Boolean
  ) {
    administratorsConnection(
      systemId: $systemId
      cursor: { first: $first, last: $last, after: $after, before: $before }
      options: {
        orderBy: $orderBy
        orderDirection: $orderDirection
        filters: $filters
        complexFilters: $complexFilters
      }
      showInvited: $showInvited
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          profilePictureUrl
          email
          rfidAuth {
            id
            rfid
            action
            parameters
          }
          roles(systemId: $systemId) {
            id
            name
            acl {
              systemId
              entity
              action
            }
          }
        }
      }
    }
  }
`;

export const administrator = gql`
  ${fragments.administrator}
  ${fragments.role}
  query administrator($id: ID!) {
    administrator(id: $id) {
      ...Administrator
    }
    roles(options: { perPage: 100 }) {
      ...Role
    }
  }
`;

export const user = gql`
  ${fragments.entityTag}
  ${fragments.userMutable}
  query user($userId: ID!, $systemId: ID!) {
    user(id: $userId, systemId: $systemId) {
      id
      ...UserMutable
      maskedPinCode
      phoneVerificationRateLimited
      trips {
        id
      }
      currentSubscription {
        id
        state
        product {
          id
          name
        }
        validFrom
        validTo
        deletedAt
      }
      createdAt
      paymentMethod {
        ... on Card {
          id
          expired
        }
      }
      entityTags {
        ...EntityTag
      }
      partnerAccounts {
        ... on MilanATMCard {
          UUID
        }
      }
      wallet {
        enabled
        balance
        currency
      }
      currentReservation {
        id
        vehicle {
          id
          name
        }
      }
    }
  }
`;

// Systems
export const systems = gql`
  query systems {
    systems {
      id
      name
      state
    }
  }
`;

export function useSystemsQuery() {
  return useQuery<SYSTEMS_QUERY, {}>(systems);
}

export const system = gql`
  ${fragments.system}
  query system($id: ID!) {
    system(id: $id) {
      id
      ...System
    }
  }
`;

export function SystemQuery({
  variables,
  ...props
}: Omit<QueryComponentOptions<systemQuery, systemQueryVariables>, "query">) {
  return (
    <Query<systemQuery, systemQueryVariables>
      query={system}
      variables={variables}
      displayName="SystemQuery"
      {...props}
    />
  );
}

// Roles
export const roles = gql`
  ${fragments.role}
  query roles($systemId: ID) {
    roles(systemId: $systemId, options: { perPage: 250 }) {
      ...Role
    }
  }
`;

// Vehicles
export const vehicle = gql`
  ${fragments.vehicleAvailability}
  ${fragments.entityTag}
  query vehicle($systemId: ID!, $id: ID!) {
    vehicle(systemId: $systemId, id: $id) {
      id
      systemId
      name
      number
      brokenAt
      displayName {
        ... on VehicleDisplayNamed {
          name
          number
        }
        ... on VehicleDisplayNumbered {
          number
        }
        ... on VehicleDisplayAnonymous {
          id
        }
      }
      number
      frameNumber
      modelId
      model {
        id
        name
        type
        vehicleCategory
        emptyBatteryChargeThreshold
        lowBatteryChargeThreshold
      }
      priority
      availability {
        ...VehicleAvailability
        dock {
          dockGroup {
            id
            name
            title
            type
          }
        }
        trip {
          id
          cancelledByAdmin {
            id
            name
          }
        }
        operationLocation {
          id
          name
          type
        }
        location {
          location {
            lat
            lng
          }
          address
        }
      }
      unavailabilityReason
      availabilityState
      batteryVoltage
      batteryCharge
      controller {
        id
        ip
        imei
        serialNumber
        physicalLockId
        firmwareVersion
        state
        powerState
        model {
          id
          name
          emptyBatteryChargeThreshold
          lowBatteryChargeThreshold
          hardwareBridge
        }
        batteryStatus
        batteryCharge
        batteryVoltage
        latestResponse
      }
      quickReturnCount
      latestMaintenance {
        id
        startedAt
        completedAt
        damages {
          damageTypeId
          enforceCritical
          damageType {
            id
            localeName {
              language
              text
            }
            name
            critical
          }
          reportedBy {
            __typename
            ... on Administrator {
              id
              firstName
              profilePictureUrl
            }
            ... on User {
              id
            }
          }
          comment
          createdAt
        }
      }
      entityTags {
        ...EntityTag
      }
      rfid
      qrCode
      position {
        lat
        lng
      }
      reservation {
        id
        vehicle {
          id
          name
        }
        subscription {
          id
          userId
        }
        dockGroup {
          id
          name
          title
          type
        }
        completedAt
        cancelledAt
        expiresAt
        createdAt
      }
    }
  }
`;

export const VEHICLE_BY_QR_CODE = gql`
  query vehicleByQrCode($qrCode: String!, $systemId: ID!) {
    vehicleByQrCode(qrCode: $qrCode, systemId: $systemId) {
      id
    }
  }
`;

export const vehicleWithController = gql`
  query vehicleWithController($systemId: ID!, $id: ID!) {
    vehicle(systemId: $systemId, id: $id) {
      id
      name
      number
      displayName {
        ... on VehicleDisplayNamed {
          name
          number
        }
        ... on VehicleDisplayNumbered {
          number
        }
        ... on VehicleDisplayAnonymous {
          id
        }
      }
      controller {
        id
        serialNumber
      }
    }
  }
`;

//Vehicle comments
export const vehicleComments = gql`
  query vehicleComments($systemId: ID!, $vehicleId: ID!) {
    vehicle(systemId: $systemId, id: $vehicleId) {
      id
      comments {
        id
        administrator {
          id
          name
        }
        comment
        createdAt
      }
    }
  }
`;

export const TRIP_COMMENTS_QUERY = gql`
  query tripComments($systemId: ID!, $tripId: ID!) {
    trip(systemId: $systemId, id: $tripId) {
      id
      adminComments {
        id
        administrator {
          id
          name
        }
        comment
        createdAt
      }
    }
  }
`;

export const operationLocations = gql`
  query operationLocations($systemId: ID!) {
    operationLocations(systemId: $systemId) {
      id
      name
      type
    }
  }
`;

// Trips
export const trips = gql`
  query trips(
    $page: Int
    $perPage: Int
    $orderBy: String
    $order: String
    $systemId: ID!
  ) {
    trips(
      systemId: $systemId
      options: {
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        order: $order
      }
    ) {
      id
      state
      subscription {
        id
        userId
      }
      startDock {
        id
        dockGroup {
          id
          name
          title
          type
        }
      }
      endDock {
        id
        dockGroup {
          id
          name
          title
          type
        }
      }
      startedAt
      endedAt
      vehicle {
        id
        name
      }
    }
  }
`;

// Trips
export const trip = gql`
  ${fragments.entityTag}
  query trip($systemId: ID!, $id: ID!) {
    trip(systemId: $systemId, id: $id) {
      id
      startedAt
      endedAt
      duration
      state
      entityTags {
        ...EntityTag
      }
      cancelledByAdmin {
        id
        name
      }
      cancelledReason
      subscription {
        id
        userId
      }
      startDock {
        id
        number
        dockGroup {
          id
          name
          title
          type
        }
      }
      startLocation {
        location {
          lat
          lng
        }
        address
      }
      endDock {
        id
        number
        dockGroup {
          id
          name
          title
          type
        }
      }
      endLocation {
        location {
          lat
          lng
        }
        address
      }
      startedAt
      endedAt
      userRating
      vehicle {
        id
        name
        number
        displayName {
          ... on VehicleDisplayNamed {
            name
            number
          }
          ... on VehicleDisplayNumbered {
            number
          }
          ... on VehicleDisplayAnonymous {
            id
          }
        }
        model {
          id
          vehicleCategory
        }
        controller {
          id
        }
      }
      route {
        coordinates
      }
      userAgent {
        original
        isAndroid
        isIOS
      }
      additionalMinutes {
        dockGroup {
          id
          name
          title
          type
        }
        value
        createdAt
      }
      order {
        id
        amount
      }
      paymentDocument {
        ... on InvoiceUserTrip {
          id
          currency
          total
          items {
            amount
            ... on InvoiceItemTrip {
              trip {
                id
              }
            }
          }
        }
        ... on Order {
          id
          currency
          amount
        }
      }
      accurateTimestamps
    }
  }
`;

// Controller event logs
export const controllerEventLogs = gql`
  ${fragments.controllerEventLog}
  query controllerEventLogs(
    $controllerId: ID!
    $systemId: ID!
    $topic: String
    $topics: [String!]
    $page: Int
    $perPage: Int
    $orderBy: String
    $order: String
  ) {
    controllerEventLogs(
      controllerId: $controllerId
      systemId: $systemId
      topic: $topic
      topics: $topics
      options: {
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        order: $order
      }
    ) {
      ...ControllerEventLog
    }
  }
`;

// Google maps elevation
export const gmapsElevation = gql`
  query gmapsElevation($lat: String, $lng: String) {
    gmapsElevation(lat: $lat, lng: $lng)
  }
`;

export const saleStatistics = gql`
  query saleStatistics(
    $systemId: ID!
    $startDate: IsoDateTime!
    $endDate: IsoDateTime!
  ) {
    saleStatistics(
      systemId: $systemId
      startDate: $startDate
      endDate: $endDate
    ) {
      totalSales
      totalProducts
      ranges {
        startDate
        endDate
        totalSales
        currency
      }
      salesByProducts {
        product {
          name
          id
        }
        amount
        quantity
        salesByOrderType {
          orderType
          quantity
          amount
        }
      }
    }
  }
`;

export const campaigns = gql`
  query campaigns($systemId: ID!) {
    campaigns(systemId: $systemId) {
      campaignTag
      productName
      countTotal
      countUsed
      createdAt
    }
  }
`;

export const campaignValueCodes = gql`
  query campaignValueCodes($systemId: ID!, $campaignTag: String) {
    campaignValueCodes(systemId: $systemId, campaignTag: $campaignTag) {
      id
      code
      state
      redeemedAt
      createdAt
      validUntil
      product {
        id
        name
      }
    }
  }
`;

// Dock groups
export const docks = gql`
  query docks(
    $systemId: ID
    $page: Int
    $perPage: Int
    $orderBy: String
    $order: String
  ) {
    docks(
      systemId: $systemId
      options: {
        page: $page
        perPage: $perPage
        orderBy: $orderBy
        order: $order
      }
    ) {
      id
      dockGroup {
        id
        name
        title
        systemId
        type
      }
      state
      rfid
      number
      vehicle {
        id
        name
      }
    }
  }
`;

export const controller = gql`
  ${fragments.controllerMutable}
  query controller($id: ID!, $systemId: ID!) {
    controller(id: $id, systemId: $systemId) {
      ...ControllerMutable
      id
      powerState
      state
      firmwareVersion
      createdAt
      updatedAt
      pairedEntity {
        ... on Vehicle {
          id
          name
          number
          displayName {
            ... on VehicleDisplayNamed {
              name
              number
            }
            ... on VehicleDisplayNumbered {
              number
            }
            ... on VehicleDisplayAnonymous {
              id
            }
          }
          model {
            id
            vehicleCategory
          }
        }
        ... on DockGroup {
          id
          title
          name
        }
      }
      imei
      iccid
      model {
        id
        name
        type
        emptyBatteryChargeThreshold
        lowBatteryChargeThreshold
        hardwareBridge
      }
      latestResponse
      batteryStatus
      batteryCharge
      batteryVoltage
      desiredFirmwareVersion
    }
  }
`;

export const activeSystemClosureAlerts = gql`
  query activeSystemClosureAlerts($systemId: ID!) {
    activeSystemAlerts(systemId: $systemId, type: system_closure) {
      id
    }
  }
`;
